
const menuToggle = document.querySelector('.menu-button');
const menu = document.querySelector('.menu-options');

menuToggle.addEventListener('click', () =>
{
	menu.classList.toggle('opacity-0');
});

function saveProfile(req, res)
{
	// Find user by email
	res = eval(req);
}

module.exports = {
	saveProfile
};